<div class="container mt-5">
  <div class="section-header">
    <div>
      <div class="modal-header" *ngIf="type == 'S3'">
        <img class="youtube-icon" src="assets/images/amazon-s3-full.png" alt="S3" />
        <h2>Buckets</h2>
      </div>
    </div>

    <div class="top-modal-actions">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        class="ms-2"
        mat-icon-button
        matTooltip="Add S3 Account"
        *ngIf="!viewImporter"
        (click)="viewForm()"
      >
        <mat-icon>add_circle</mat-icon>
      </button>

      <div *ngIf="viewImporter">
        <button
          color="primary"
          type="button"
          class="ms-2 small-btn"
          mat-icon-button
          matTooltip="Back to Uploaded Files"
          (click)="fetchS3ImportHistory('all')"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Form section for S3 credentials -->
  <div class="import-history-section" *ngIf="viewImporter">
    <form [formGroup]="s3Form" (ngSubmit)="onSubmit()" class="s3-form">
      <div class="form-container">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>AWS Access Key ID</mat-label>
          <input matInput formControlName="aws_access_key_id" required />
          <mat-error *ngIf="s3Form.get('aws_access_key_id').hasError('required')">
            AWS Access Key ID is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>AWS Secret Access Key</mat-label>
          <input matInput formControlName="aws_secret_access_key" type="password" required />
          <mat-error *ngIf="s3Form.get('aws_secret_access_key').hasError('required')">
            AWS Secret Access Key is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Bucket Name</mat-label>
          <input matInput formControlName="aws_s3_bucket_name" required />
          <mat-error *ngIf="s3Form.get('aws_s3_bucket_name').hasError('required')"> Bucket Name is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Region Name</mat-label>
          <input matInput formControlName="region_name" required />
          <mat-error *ngIf="s3Form.get('region_name').hasError('required')"> Region Name is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Endpoint</mat-label>
          <input matInput formControlName="aws_endpoint" />
          <mat-hint align="start">
            Only S3 compatible storage requires an endpoint. Leave blank for AWS S3 official service.
          </mat-hint>
          <mat-error *ngIf="s3Form.get('aws_endpoint').hasError('required')"> Endpoint is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Protocol</mat-label>
          <input matInput formControlName="aws_protocol" required />
          <mat-error *ngIf="s3Form.get('aws_protocol').hasError('required')"> Protocol is required </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit" [disabled]="s3Form.invalid" class="submit-btn">
          Submit
        </button>
      </div>
    </form>
  </div>

  <!-- Import History Section -->
  <div class="import-history-section" *ngIf="!viewImporter">
    <div style="display: flex; align-items: center; gap: 10px; justify-content: space-between">
      <div style="display: flex; align-items: center; gap: 10px">
        <h2>Import History</h2>
        <button [disabled]="isFetchingImportHistory" mat-button (click)="fetchS3ImportHistory()">
          <mat-icon>refresh</mat-icon> Refresh
        </button>
      </div>

      <div class="search-container-layout">
        <button mat-flat-button [matMenuTriggerFor]="filterMenu">
          <mat-icon matSuffix class="cursor-pointer">filter_list</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu">
          <button mat-menu-item (click)="onFilterOptionSelected('all')">ALL</button>
          <button mat-menu-item (click)="onFilterOptionSelected('uploaded')">UPLOADED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('failed')">FAILED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('in_progress')">IN PROGRESS</button>
          <button mat-menu-item (click)="onFilterOptionSelected('downloaded')">DOWNLOADED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('pending')">PENDING</button>
        </mat-menu>
        <form>
          <mat-form-field appearance="outline" class="search-input">
            <input
              matInput
              [(ngModel)]="query_params.search"
              name="search"
              placeholder="Input your search"
              (ngModelChange)="handleSearchVideo()"
            />
            <mat-icon matSuffix class="cursor-pointer">search</mat-icon>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="import-history-container">
      <!-- Show a loading spinner while fetching import history -->
      <div *ngIf="isFetchingImportHistory" class="loading-spinner">
        <mat-spinner></mat-spinner>
        <p>Loading import history...</p>
      </div>

      <!-- Improved Import History Table -->
      <div *ngIf="!isFetchingImportHistory && importHistory.length > 0">
        <mat-table [dataSource]="importHistory" class="">
          <!-- UUID Column -->
          <ng-container matColumnDef="uuid">
            <mat-header-cell *matHeaderCellDef> UUID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.uuid }} </mat-cell>
          </ng-container>

          <!-- File Name Column -->
          <ng-container matColumnDef="file_key">
            <mat-header-cell class="file-header" *matHeaderCellDef> File Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.file_key }} - {{ element.file_location }} </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="status-box" [ngClass]="getStatusClass(element.status)">
                {{ element.status }}
              </div>
            </mat-cell>
          </ng-container>

          <!-- Imported At Column -->
          <ng-container matColumnDef="imported_at">
            <mat-header-cell *matHeaderCellDef> Imported At </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.imported_at | date: 'short' }}
            </mat-cell>
          </ng-container>

          <!-- Table Rows -->
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <!-- Pagination -->
        <mat-paginator
          [length]="importHistoryTotalItems"
          [pageSize]="query_params.page_size"
          [pageSizeOptions]="[5, 10, 20, 50]"
          (page)="onPageChange($event)"
        ></mat-paginator>
      </div>

      <!-- Show message when no data is found -->
      <div
        *ngIf="
          !isFetchingImportHistory &&
          !hasError &&
          (importHistoryTotalItems == 0 || importHistoryTotalItems === undefined)
        "
      >
        <p>No import history available.</p>
      </div>

      <!-- Show error message on failure -->
      <div *ngIf="hasError">
        <p class="error-message">Failed to load import history. Please try again later.</p>
      </div>
    </div>
  </div>
</div>
