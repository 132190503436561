import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tutorial-list-mat-dialog',
  templateUrl: './tutorial-list-mat-dialog.component.html',
  styleUrls: ['./tutorial-list-mat-dialog.component.scss'],
})
export class TutorialListMatDialogComponent implements OnInit {
  public videoSource: any;
  videoWidth: number = 720;
  videoHeight: number = 405;

  constructor(
    public dialogRef: MatDialogRef<TutorialListMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.setVideoPlayerSize();
    this.videoSource = this.data[0]?.source;
  }

  openTutorial(videoId: number): void {
    this.videoSource = this.data[videoId]?.source;
  }

  handleCancel() {
    this.dialogRef.close(false);
  }

  setVideoPlayerSize() {
    const windowSize = window.innerWidth;

    if (windowSize < 768) {
      this.videoWidth = 590;
    }

    if (windowSize < 568) {
      this.videoWidth = 390;
    }

    if (windowSize < 440) {
      this.videoWidth = 320;
    }

    if (windowSize < 368) {
      this.videoWidth = 250;
    }
  }
}
