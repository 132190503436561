import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/core';
import { Category, Playlist, Video } from '@app/shared/domain';
import { map } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VideoCategoryService {
  private readonly selectedCategory$: BehaviorSubject<Category> = new BehaviorSubject<Category>(null);
  private readonly changeOrder$: Subject<Category> = new Subject<Category>();
  private readonly selectedVODPlaylist$: BehaviorSubject<any> = new BehaviorSubject<Playlist>(null);
  private readonly selectedVideo$: BehaviorSubject<Video> = new BehaviorSubject<Video>(null);

  constructor(private apiService: ApiService, private router: Router) {}

  setSelectedVideo(selected: Video) {
    this.selectedVideo$.next(selected);
  }

  getSelectedVideo() {
    return this.selectedVideo$.asObservable().pipe(distinctUntilChanged());
  }

  setSelectedVODPlaylist(selected: Playlist) {
    this.selectedVODPlaylist$.next(selected);
  }

  getSelectedVODPlaylist() {
    return this.selectedVODPlaylist$.asObservable().pipe(distinctUntilChanged());
  }

  setSelectedCategory(selected: Category) {
    this.setSelectedVODPlaylist(null);
    this.selectedCategory$.next(selected);
  }

  getSelectedCategory() {
    return this.selectedCategory$.asObservable().pipe(distinctUntilChanged());
  }

  setChangeOrder(category: Category) {
    this.changeOrder$.next(category);
  }

  getChangeOrder() {
    return this.changeOrder$.asObservable().pipe();
  }

  getCategories(data: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/video-categories/', params);
  }

  updateCategory(fmData: any, categoryId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/video-categories/${categoryId}/`, fmData);
  }

  updateCategoryThumbnail(categoryId: string, fmData: FormData) {
    return this.apiService.put(`/api/v1/video-categories/${categoryId}/update-category-thumbnail/`, fmData);
  }

  updateCategoryFeatured(categoryId: string, fmData: FormData) {
    return this.apiService.patch(`/api/v1/video-categories/${categoryId}/update-category-featured/`, fmData);
  }

  deleteCategory(categoryId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/video-categories/${categoryId}/`);
  }

  createCategory(fmData: FormData): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/`, fmData);
  }

  changeParentCategory(categoryId: string, parentId: string): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/change-parent-category/${categoryId}/${parentId}/`);
  }

  getVideos(categoryId: string): Observable<any> {
    return this.apiService.get(`/api/v1/video-categories/${categoryId}/`);
  }

  getallVideos(categories_list: any): Observable<any> {
    const requestData = {
      categories_list: categories_list.join(','),
    };
    return this.apiService.post('/api/v1/video-categories/all-videos-category/', requestData);
  }

  getVideosByPlaylist(playlistId: string): Observable<any> {
    return this.apiService.get(`/api/v1/playlists/${playlistId}/`);
  }

  addVideo(categoryId: string, videoId: string): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/add-video/${categoryId}/${videoId}/`);
  }

  removeVideoVOD(videoCategoryId: string): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/remove-category-video/${videoCategoryId}/`);
  }

  removeVideo(videoId: string, categoryId: string): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/remove-video/${categoryId}/${videoId}/`);
  }

  changeOrder(category: any, categoryId: string): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/${categoryId}/change-order/`, category);
  }

  addVideoPlaylist(channelId: string, videoCateId: string): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/create-video-playlist/${channelId}/${videoCateId}/`);
  }

  cloneCategory(category_id: string): Observable<any> {
    return this.apiService.post(`/api/v1/video-categories/${category_id}/clone-category/`);
  }

  changeOrderCategories(data: any): Observable<any> {
    return this.apiService.post('/api/v1/video-categories/change-order-categories/', data);
  }
}
