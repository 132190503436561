import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';
import { SocialType, Video, MediaJobStatus, GenreDetails } from '@app/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private readonly isLoadingTimeLine$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoadingVodPlaylist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoadingVodCategory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoadingVideoSettings$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly typeSocial$: BehaviorSubject<SocialType> = new BehaviorSubject<SocialType>(null);

  setTypeSocial(type: SocialType) {
    this.typeSocial$.next(type);
  }

  getTypeSocial() {
    return this.typeSocial$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoadingVideoSettings(isLoading: boolean) {
    this.isLoadingVideoSettings$.next(isLoading);
  }

  getIsLoadingVideoSettings() {
    return this.isLoadingVideoSettings$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoadingTimeLine(isLoading: boolean) {
    this.isLoadingTimeLine$.next(isLoading);
  }

  getIsLoadingTimeLine() {
    return this.isLoadingTimeLine$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoadingVodPlaylist(isLoading: boolean) {
    this.isLoadingVodPlaylist$.next(isLoading);
  }

  getIsLoadingVodPlaylist() {
    return this.isLoadingVodPlaylist$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoadingVodCategory(isLoading: boolean) {
    this.isLoadingVodCategory$.next(isLoading);
  }

  getIsLoadingVodCategory() {
    return this.isLoadingVodCategory$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  getIsLoading() {
    return this.isLoading$.asObservable().pipe(distinctUntilChanged());
  }

  constructor(private apiService: ApiService) {}

  getVideos(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v3/videos/', params);
  }

  getVideo(videoId: string): Observable<any> {
    return this.apiService.get(`/api/v1/videos/${videoId}`);
  }

  updateVideoThumbnail(fmData: FormData, videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/update-video-thumbnail/`, fmData);
  }

  updateVideoBanner(fmData: FormData, videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/upload-video-banner/`, fmData);
  }

  updateVideoTrailer(data: any, videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/save-trailer/`, data);
  }

  updateVideo(video: Video, videoId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/videos/${videoId}/`, video);
  }

  updateVideoPartial(videoJobStatus: MediaJobStatus, videoId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/videos/${videoId}/`, videoJobStatus);
  }

  softDeleteVideo(videoId: string): Observable<any> {
    return this.apiService.delete(`/api/v3/videos/${videoId}/`);
  }

  deleteVideo(videoId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/videos/${videoId}/`);
  }

  deleteVideoV3(videoId: string): Observable<any> {
    return this.apiService.delete(`/api/v3/videos/${videoId}/`);
  }

  updateVideoSeo(data: any, videoId: string): Observable<any> {
    return this.apiService.post(`/api/v1/videos/${videoId}/seo/`, data);
  }

  updateVideoAvailabilityAccess(data: any, videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/availability_access/`, data);
  }

  uploadVideoThumbnail(data: any, videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/upload-video-thumbnail/`, data);
  }

  removeVideoBanner(videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/remove-video-banner/`);
  }
  removeVideoPoster(videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/remove-video-poster/`);
  }

  removeVideoTitleLogo(videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/remove-video-title-logo/`);
  }

  removeVideoThumbnail(videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/remove-video-thumbnail/`);
  }

  generateVideoThumbnail(videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/generate-video-thumbnail/`);
  }

  attachmentFiles(fmData: FormData, videoId: string): Observable<any> {
    return this.apiService.post(`/api/v1/videos/${videoId}/save-attach-file/`, fmData);
  }

  removeVideoSubtitle(videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/remove-video-subtitle/`);
  }

  uploadVideo(fmData: FormData): Observable<Video> {
    return this.apiService.post('/api/v2/upload/video/', fmData);
  }

  replaceVideoV3(data: FormData): Observable<any> {
    return this.apiService.put(`/api/v3/videos/replace_video/`, data);
  }

  replaceVideo(videoId: string, data: FormData): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/replace-video-file/`, data);
  }

  reEncoder(videoId: string, data: any): Observable<Video> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.post(`/api/v1/videos/${videoId}/start-encoder/?${params.toString()}`);
  }

  reMerging(videoId: string): Observable<Video> {
    // userd for reMerging option
    return this.apiService.post(`/api/v1/videos/${videoId}/start-merging/`);
  }

  getSocialAuthorizationUrl(type: string): Observable<any> {
    return this.apiService.get(`/api/v1/${type}/get-authorization-url/`);
  }

  generateTokenSocial(type: string, data: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.post(`/api/v1/${type}/generate-token/?${params.toString()}`);
  }
  getListVideoFromSocial(type: string, data: any): Observable<any> {
    return this.apiService.post(`/api/v1/${type}/get-videos/`, data);
  }

  importVideoFormSocial(type: string, data: any): Observable<any> {
    return this.apiService.post(`/api/v1/${type}/import-videos/`, data);
  }

  getYoutubeAccounts(): Observable<any> {
    return this.apiService.get('/api/v1/youtube/get-accounts/');
  }

  getVideoFromYoutubeUrl(data: any): Observable<any> {
    return this.apiService.post('/api/v1/youtube/get-videos/', data);
  }

  importSingleVideoFormYoutubeUrl(video_url: string): Observable<any> {
    return this.apiService.post('/api/v1/youtube/import-single-video/', { video_url });
  }

  checkVideoNameExits(title: string): Promise<Video> {
    return this.apiService.get(`/api/v1/video-library/get-by-title/?title=${title}`).toPromise();
  }

  getDownloadLink(videoId: string): Observable<any> {
    return this.apiService.get(`/api/v1/videos/${videoId}/get-download-link/`);
  }

  getGenreOptions(verbose: boolean = false): Observable<any> {
    return this.apiService.get(`/api/v3/videos/genres/?verbose=${verbose}`);
  }

  getAllGenreDetails(): Observable<GenreDetails[]> {
    return this.apiService.get(`/api/v3/videos/genre-details/all/`);
  }

  createGenreDetails(fmData: FormData): Observable<GenreDetails> {
    return this.apiService.post(`/api/v3/videos/genre-details/`, fmData);
  }

  updateGenreDetails(fmData: FormData, genreDetailsId: number): Observable<GenreDetails> {
    return this.apiService.patch(`/api/v3/videos/genre-details/${genreDetailsId}/`, fmData);
  }

  deleteGenreDetails(genreDetailsId: number): Observable<GenreDetails> {
    return this.apiService.delete(`/api/v3/videos/genre-details/${genreDetailsId}/`);
  }

  getMovieRatingOptions(): Observable<any> {
    return this.apiService.get(`/api/v3/videos/movie-ratings/`);
  }

  updateVideoAsAd(data: any, videoId: string): Observable<any> {
    return this.apiService.put(`/api/v1/videos/${videoId}/video_as_ad/`, data);
  }

  attachAdToVideo(data: any, videoId: string): Observable<any> {
    return this.apiService.put(`/api/v3/videos/${videoId}/attach-ad-video/`, data);
  }

  getAdVideo(videoId: string): Observable<any> {
    return this.apiService.get(`/api/v3/videos/${videoId}/get-ad-video/`);
  }

  removeAdVideo(videoId: string): Observable<any> {
    return this.apiService.put(`/api/v3/videos/${videoId}/remove-ad-video/`);
  }
}
