import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { PageEvent } from '@angular/material/paginator';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { S3ImporterService } from '@app/shared/services/s3-importer';
import { AuthService } from '@app/auth/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-s3-importer',
  templateUrl: './s3-importer.component.html',
  styleUrls: ['./s3-importer.component.scss'],
})
export class S3ImporterComponent implements OnInit {
  selectedVideos: string[] = [];
  hasError: boolean = false;
  selectedPageSize: number = 10;
  viewImporter: boolean = false;
  query_params = {
    tenant_id: '',
    import_from: '',
    start_date: '',
    end_date: '',
    page_size: 10,
    page: 1,
    status: '',
    search: '',
  };
  importHistory: any[] = [];
  isFetchingImportHistory: boolean = false;
  importHistoryTotalItems: number = 0;
  selectAllReImport: boolean = false;
  selectedItemsToReImport: any = [];
  tenant_id: string;
  type: string;
  displayedHistory: any[] = [];
  displayedColumns: string[] = ['uuid', 'file_key', 'status', 'imported_at'];

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private s3ImporterApiService: S3ImporterService,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = data.type;
  }

  s3Form: FormGroup;

  ngOnInit(): void {
    // Set default start_date to January 1st of the current year
    const currentDate = new Date();
    this.query_params.start_date = new Date(currentDate.getFullYear(), 0, 1).toISOString();
    // Set default end_date to current date
    this.query_params.end_date = currentDate.toISOString();

    // Get tenant info
    const tenant = this.authService.getTenant();
    this.tenant_id = tenant;
    this.query_params.tenant_id = this.tenant_id;
    this.fetchS3ImportHistory();

    this.s3Form = this.fb.group({
      aws_access_key_id: ['', [Validators.required]],
      aws_secret_access_key: ['', [Validators.required]],
      aws_s3_bucket_name: ['', [Validators.required]],
      region_name: ['', [Validators.required]],
      aws_endpoint: ['', []],
      aws_protocol: ['', [Validators.required]],
    });
  }

  selectAll(): void {
    this.selectedVideos = [...this.importHistory]; // Copy all videos into selectedVideos
    console.log('All videos selected:', this.selectedVideos);
  }

  // Submit form to save S3 credentials
  onSubmit() {
    if (this.s3Form.valid) {
      this.s3ImporterApiService.createS3Credentials({ ...this.s3Form.value, tenant_id: this.tenant_id }).subscribe({
        next: () => {
          this.snackBar.open('S3 Credentials Saved!', 'Close', { duration: 2000 });
          this.notifier.notify('success', `S3 Account stored. Import automation will start soon.`);
        },
        error: () => {
          this.snackBar.open('Error saving credentials!', 'Close', { duration: 3000 });
        },
      });
    }
  }

  // Fetch S3 import history with pagination and filters
  fetchS3ImportHistory(status: string = '', hideLoading: boolean = false) {
    this.isFetchingImportHistory = true;

    if (status === 'all') {
      this.query_params.status = '';
    }

    this.viewImporter = false;
    this.query_params.import_from = this.type;
    this.hasError = false;

    this.s3ImporterApiService.fetchS3ImportHistory(this.query_params).subscribe(
      (response: any) => {
        console.log('API call succeeded:', response);

        if (response.message) {
          console.warn('No records found:', response.message);
          this.importHistory = [];
          this.importHistoryTotalItems = 0;
        } else {
          this.updateDisplayedHistory(0, this.query_params.page_size);
          const startIndex = (this.query_params.page - 1) * this.query_params.page_size;
          const endIndex = startIndex + this.query_params.page_size;
          this.importHistory = response.slice(startIndex, endIndex).map((item: any) => ({
            ...item,
            selected: false,
          }));
          this.importHistoryTotalItems = response.length;
        }

        this.isFetchingImportHistory = false;
        this.hasError = false;
      },
      (error) => {
        console.error('API call failed:', error);
        this.hasError = true;
        this.isFetchingImportHistory = false;
      }
    );
  }

  // Update the displayed data based on the current page
  updateDisplayedHistory(startIndex: number, endIndex: number): void {
    this.displayedHistory = this.importHistory.slice(startIndex, endIndex);
  }

  // Handle status filter selection
  onFilterOptionSelected(job_status: string) {
    this.query_params.status = job_status === 'all' ? '' : job_status;
    this.handleSearchVideo();
  }

  // Handle search video based on filter
  handleSearchVideo() {
    this.query_params.page_size = 10;
    this.query_params.page = 1;
    this.fetchS3ImportHistory();
  }

  // Handle pagination changes
  onPageChange(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize;
    this.updateDisplayedHistory(startIndex, endIndex);
  }

  // Get human-readable status
  getStatus(status: string): string {
    const statusMap: { [key: string]: string } = {
      NOT_STARTED: 'Not Started',
      PENDING: 'Pending',
      IN_PROGRESS: 'In Progress',
      DOWNLOADING: 'Downloading',
      DOWNLOADED: 'Downloaded',
      STALLED: 'stalled',
      ENCODING: 'Encoding',
      ENCODED: 'Encoded',
      SUCCESS: 'Success',
      DELAYED: 'Delayed',
      UPLOADED: 'Uploaded',
      UPLOADING: 'Uploading',
      FAILED: 'Failed',
      ERRORED: 'Erroed',
      COMPLETED: 'Completed',
    };
    return statusMap[status] || 'Unknown';
  }

  getStatusClass(status: string): string {
    const statusClasses: { [key: string]: string } = {
      NOT_STARTED: 'status-not-started',
      PENDING: 'status-pending',
      IN_PROGRESS: 'status-in-progress',
      DOWNLOADING: 'status-downloading',
      DOWNLOADED: 'status-downloaded',
      STALLED: 'status-stalled',
      ENCODING: 'status-encoding',
      ENCODED: 'status-encoded',
      SUCCESS: 'status-success',
      DELAYED: 'status-delayed',
      UPLOADED: 'status-uploaded',
      UPLOADING: 'status-uploading',
      FAILED: 'status-failed',
      ERRORED: 'status-errored',
      COMPLETED: 'status-completed',
    };
    return statusClasses[status] || 'status-unknown';
  }

  // Toggle select all for re-import
  toggleSelectAll(checked: boolean): void {
    this.importHistory.forEach((item) => (item.selected = checked));
    this.updateSelectedCount();
  }

  // Update selected items count
  updateSelectedCount(): void {
    this.selectedItemsToReImport = this.importHistory
      .filter((item) => item.selected) // Filter selected items
      .map((item) => item.uuid); // Map to UUIDs
  }

  // View form method
  viewForm() {
    this.viewImporter = true;
    this.selectedItemsToReImport = [];
  }
}
