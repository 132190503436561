import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { VideoImporterService } from '@app/shared/services/video-importer';
import { PageEvent } from '@angular/material/paginator';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-video-importer-v2',
  templateUrl: './video-importer-v2.component.html',
  styleUrls: ['./video-importer-v2.component.scss'],
})
export class VideoImporterV2Component implements OnInit {
  selectedVideos: string[] = [];
  hasError: boolean = false;
  selectedPageSize: number = 10;
  viewImporter: boolean = true;
  query_params = {
    import_from: '',
    page_size: 10,
    page: 1,
    status: '',
    search: '',
  };
  importHistory: any[];
  isFetchingImportHistory: boolean = false;
  videos: any[] = [];
  isImporting: boolean = false;
  isFetching: boolean = false;
  contentUrl: string = '';
  name: string = '';
  type: string = '';
  importHistoryTotalItems: number = 0;
  selectAllReImport: boolean = false;
  selectedItemsToReImport: any = [];
  selectedPage: number = 1;
  totalItems: number = 0;
  availablePages: number[] = [];
  private intervalSubscription!: Subscription;

  constructor(
    private importerApiService: VideoImporterService,
    public dialog: MatDialog,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<VideoImporterModelV2>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = data.type;
  }

  ngOnInit(): void {}

  fetchYoutubeVideos() {
    const channelUrl = this.contentUrl;
    let payload = {
      url: channelUrl,
      page_size: this.selectedPageSize,
    };
    this.isFetching = true;
    this.hasError = false;
    this.videos = [];
    this.importerApiService.fetchYoutubeVideos(payload).subscribe(
      (data: any) => {
        this.videos = data.videos.map((video: any) => ({
          ...video,
          selected: false,
        }));
        this.notifier.notify('success', data.message);
        this.isFetching = false;
      },
      (error) => {
        this.hasError = true;
        this.notifier.notify('error', error);
        this.isFetching = false;
      }
    );
  }

  fetchVimeoVideos() {
    const name = this.name;
    let payload = {
      name: name,
      oauth_token: localStorage.getItem('vimeo_token'),
      page_size: this.selectedPageSize,
      page: this.selectedPage,
    };
    this.isFetching = true;
    this.hasError = false;
    this.videos = [];
    this.importerApiService.fetchAllVimeoVideos(payload).subscribe(
      (data: any) => {
        this.videos = data.videos.map((video: any) => ({
          ...video,
          selected: false,
        }));
        this.totalItems = data.total_videos;
        this.selectedPage = data.page;

        this.getAvailablePages();
        this.notifier.notify('success', data.message);
        this.isFetching = false;
      },
      (error) => {
        this.hasError = true;
        this.notifier.notify('error', error);
        this.isFetching = false;
      }
    );
  }

  fetchDropboxVideos() {
    const folderUrl = this.contentUrl;
    let payload = {
      url: folderUrl,
      oauth_token: localStorage.getItem('dropbox_token'),
      page_size: this.selectedPageSize,
      page: this.selectedPage,
    };
    this.isFetching = true;
    this.hasError = false;
    this.videos = [];
    this.importerApiService.fetchDropboxVideos(payload).subscribe(
      (data: any) => {
        this.videos = data.videos.map((video: any) => ({
          ...video,
          selected: false,
        }));
        this.totalItems = data.total_videos;
        this.selectedPage = data.page;

        this.getAvailablePages();
        this.notifier.notify('success', data.message);
        this.isFetching = false;
      },
      (error) => {
        this.hasError = true;
        this.notifier.notify('error', error);
        this.isFetching = false;
      }
    );
  }

  selectVideo(video: any) {
    if (this.type == 'YouTube') {
      const index = this.selectedVideos.indexOf(video);
      if (index === -1) {
        this.selectedVideos.push(video);
      } else {
        this.selectedVideos.splice(index, 1);
      }
    } else {
      const videoUrl = video.url;
      const index = this.selectedVideos.indexOf(videoUrl);

      if (index === -1) {
        this.selectedVideos.push(videoUrl);
      } else {
        this.selectedVideos.splice(index, 1);
      }
    }
  }

  selectAll() {
    if (this.type == 'YouTube') {
      let newSelections = this.videos.filter((video) => !this.selectedVideos.includes(video));
      this.selectedVideos.push(...newSelections);
    } else {
      let allVideoUrls = this.videos.map((video) => video.url);
      let newSelections = allVideoUrls.filter((url) => !this.selectedVideos.includes(url));
      this.selectedVideos.push(...newSelections);
    }
  }

  handleImportSelectedVideos() {
    const dialogData = new ConfirmDialogModel(
      'Note',
      `Selected ${this.selectedVideos.length} ${
        this.selectedVideos.length > 1 ? 'videos' : 'video'
      } to import. Kindly note that there may be a delay in imported videos appearing in the video library. We appreciate your patience during this process.`,
      'Confirm'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '360px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (this.type == 'YouTube') {
          this.importSelectedYoutubeVideos();
        } else if (this.type == 'Vimeo') {
          this.importSelectedVimeoVideos();
        } else if (this.type == 'Dropbox') {
          this.importSelectedDropboxVideos();
        }
      }
    });
  }

  importSelectedYoutubeVideos() {
    this.isImporting = true;
    this.importerApiService.importSelectedYoutubeVideos({ video_urls: this.selectedVideos }).subscribe(
      (data: any) => {
        this.isImporting = false;
        this.notifier.notify('success', data.message);
        this.selectedVideos = [];
        setTimeout(() => {
          this.fetchImportHistory();
        }, 1000);
      },
      (error) => {
        this.isImporting = false;
        this.notifier.notify('error', error);
      }
    );
  }

  importSelectedVimeoVideos() {
    this.isImporting = true;
    this.importerApiService.importSelectedVimeoVideos({ video_urls: this.selectedVideos }).subscribe(
      (data: any) => {
        this.isImporting = false;
        this.notifier.notify('success', data.message);
        this.selectedVideos = [];
        setTimeout(() => {
          this.fetchImportHistory();
        }, 1000);
      },
      (error) => {
        this.isImporting = false;
        this.notifier.notify('error', error);
      }
    );
  }

  importSelectedDropboxVideos() {
    this.isImporting = true;
    let payload = { video_urls: this.selectedVideos, oauth_token: localStorage.getItem('dropbox_token') };
    this.importerApiService.importSelectedDropboxVideos(payload).subscribe(
      (data: any) => {
        this.isImporting = false;
        this.notifier.notify('success', data.message);
        this.selectedVideos = [];
        setTimeout(() => {
          this.fetchImportHistory();
        }, 1000);
      },
      (error) => {
        this.isImporting = false;
        this.notifier.notify('error', error);
      }
    );
  }

  fetchImportHistory(status: string = '', hideLoading: boolean = false) {
    if (status == 'all') {
      this.query_params.status = '';
    }
    this.viewImporter = false;
    this.query_params.import_from = this.type;
    this.hasError = false;
    this.importerApiService.fetchImportHistory(this.query_params).subscribe(
      (data: any) => {
        this.importHistory = data.results.map((item: any) => ({
          ...item,
          selected: false, // Initialize the selected field
        }));
        this.importHistoryTotalItems = data.total;
        this.isFetchingImportHistory = false;
      },
      (error) => {
        this.hasError = true;
        this.isFetchingImportHistory = false;
      }
    );
  }

  onFilterOptionSelected(job_status: string) {
    if (job_status == 'all') {
      this.query_params.status = '';
    } else {
      this.query_params.status = job_status;
    }

    this.handleSearchVideo(null);
  }

  handleSearchVideo(e: string) {
    this.query_params.page_size = 10;
    this.query_params.page = 1;
    this.fetchImportHistory();
  }

  onPageChange(event: PageEvent): void {
    this.query_params.page_size = event.pageSize;
    this.query_params.page = event.pageIndex + 1;
    this.fetchImportHistory();
  }

  retryImportItem(item_uuid: string) {
    let oauth_token = '';
    if (this.type == 'Dropbox') {
      oauth_token = localStorage.getItem('dropbox_token');
    }
    this.importerApiService.retryImportItem(item_uuid, oauth_token).subscribe(
      (data: any) => {
        this.fetchImportHistory();
        this.notifier.notify('success', data.message);
      },
      (error) => {
        this.notifier.notify('error', error);
      }
    );
  }

  deleteImportHistoryItem(item_uuid: string) {
    this.importerApiService.deleteImportItem(item_uuid).subscribe(
      (data: any) => {
        this.fetchImportHistory();
        this.selectedItemsToReImport = [];
        this.notifier.notify('success', data.message);
      },
      (error) => {
        this.notifier.notify('error', error);
      }
    );
  }

  getStatus(status: string): string {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'in_progress':
        return 'In Progress';
      case 'downloading':
        return 'Downloading';
      case 'uploaded':
        return 'Uploaded';
      case 'failed':
        return 'Failed';
      default:
        return 'Unknown';
    }
  }

  toggleSelectAll(checked: boolean): void {
    this.importHistory.forEach((item) => (item.selected = checked));
    this.updateSelectedCount();
  }

  updateSelectedCount(): void {
    this.selectedItemsToReImport = this.importHistory
      .filter((item) => item.selected) // Filter selected items
      .map((item) => item.uuid); // Map to UUIDs
  }

  handleReImport() {
    let oauth_token = '';
    if (this.type == 'Dropbox') {
      oauth_token = localStorage.getItem('dropbox_token');
    }
    this.importerApiService.retryImportItemsBulk(this.selectedItemsToReImport, this.type, oauth_token).subscribe(
      (data: any) => {
        this.selectAllReImport = false;
        this.selectedItemsToReImport = [];
        let hideLoading = true;
        this.fetchImportHistory('all', hideLoading);
        this.notifier.notify('success', data.message);
      },
      (error) => {
        this.notifier.notify('error', error);
      }
    );
  }

  getAvailablePages() {
    const totalPages = Math.ceil(this.totalItems / this.selectedPageSize);
    this.availablePages = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  viewImporterSection() {
    this.viewImporter = true;
    this.selectedItemsToReImport = [];
  }
}

export class VideoImporterModelV2 {
  constructor(public type: any) {}
}
