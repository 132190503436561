import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { environment as env } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface S3CreateConfig {
  awsAccessKeyId: string;
  aws_endpoint: string;
  aws_protocol: string;
  aws_s3_bucket_name: string;
  aws_secret_access_key: string;
  region_name: string;
  tenant_id: string;
}

export interface EnableS3FeatureRequest {
  tenant: string;
  authuser: any;
}

export interface EnableS3FeatureResponse {
  uuid: string;
  tenant: string;
  authuser: number;
  status: string;
  date_requested: string;
  created_at: string;
  updated_at: string;
}

@Injectable({
  providedIn: 'root',
})
export class S3ImporterService {
  constructor(private apiService: ApiService) {}

  fetchYoutubeVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/importers/`;
    return this.apiService.post(url, payload);
  }

  checkS3Feature(tenantId: string): Observable<boolean> {
    const url = `${env.serverUrl}/api/v3/importers/check-feature?tenant_id=${tenantId}`;
    return this.apiService.get(url).pipe(
      map((res) => {
        return res?.s3_imports_enabled === true;
      }),
      catchError((error) => {
        console.error('Error checking S3 feature:', error);
        return of(false);
      })
    );
  }

  createS3Credentials(payload: S3CreateConfig) {
    let url = `${env.serverUrl}/api/v3/importers/`;
    return this.apiService.post(url, payload);
  }

  fetchS3ImportHistory(params: any) {
    let url = `${env.serverUrl}/api/v3/s3-import-history`;
    const queryString = new URLSearchParams(params).toString();
    if (queryString) {
      url += `?${queryString}`;
    }
    return this.apiService.get(url);
  }

  deleteImportItem(uuid: string) {
    let url = `${env.serverUrl}/api/v3/s3-import-history/${uuid}`;
    return this.apiService.delete(url);
  }

  enableS3Feature(payload: EnableS3FeatureRequest): Observable<EnableS3FeatureResponse> {
    const url = `${env.serverUrl}/api/v3/s3-import-requests/save_request/`;
    return this.apiService.post(url, payload).pipe(
      catchError((error) => {
        console.error('Error requesting for S3 feature:', error);
        throw error;
      })
    );
  }
}
