import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CommonHelpers } from '@app/helpers';
import { AudioPlaylistService, ChannelService, VideoPlaylistService } from '@app/shared/services';
import { Playlist } from '../../domain';
import { NotifierService } from 'angular-notifier';
import { configConstans } from '@app/core';
import { startOfToday } from 'date-fns';
import { first } from 'rxjs/operators';
import { NoWhitespaceValidator } from '@app/helpers/validators';

function CheckTitle(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const str = control.value.replace(/[^\w ]/g, '');
    let isValid = !!str;
    return isValid ? null : { invalid: 'Invalid title' };
  };
}

type CreateType = 'AUDIO' | 'VIDEO';

function createPlaylistValidator() {
  return (formGroup: FormGroup) => {
    const startDateControl = formGroup.controls['startDate'];
    const startTimeControl = formGroup.controls['startTime'];

    if (startDateControl.value) {
      if (!startTimeControl.value) {
        startTimeControl.setErrors({
          required: true,
        });
      } else {
        startTimeControl.setErrors(null);
      }
    }

    if (startTimeControl.value) {
      if (!startDateControl.value) {
        startDateControl.setErrors({
          required: true,
        });
      } else {
        startDateControl.setErrors(null);
      }
    }
  };
}

@Component({
  selector: 'app-create-playlist-dialog',
  templateUrl: './create-playlist-dialog.component.html',
  styleUrls: ['./create-playlist-dialog.component.scss'],
})
export class CreatePlaylistDialogComponent implements OnInit {
  public activeChannel$ = this.channelService.getActiveChannel();
  public playlistForm: FormGroup;
  public isLoading: boolean;
  public configConstans = configConstans;
  public minDate = startOfToday();
  public minTime = new Date();
  public type: CreateType;
  public mode: string;
  public startDate: any;
  public startTime: any;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreatePlaylistDialogComponent>,
    private videoPlaylistService: VideoPlaylistService,
    private audioPlaylistService: AudioPlaylistService,
    private notifier: NotifierService,
    private channelService: ChannelService,
    @Inject(MAT_DIALOG_DATA) public data: CreatePlaylistDialogModel
  ) {
    this.type = data.type;
    this.mode = data.mode;
    this.startDate = data.date && new Date(data.date.toDateString());
    this.startTime = data.date;
  }

  ngOnInit() {
    this.buildForm();
  }

  get f() {
    return this.playlistForm?.controls;
  }

  buildForm() {
    this.playlistForm = this.formBuilder.group(
      {
        title: ['', [Validators.required, NoWhitespaceValidator(), CheckTitle()]],
        description: [''],
        status: [this.mode || configConstans.playlistStatuses.DRAFT.value, Validators.required],
        mode: [this.mode || configConstans.playlistModes.MANUAL.value, Validators.required],
        startDate: [
          this.startDate,
          this.f?.status?.value === configConstans.playlistStatuses.SCHEDULED.value && Validators.required,
        ],
        startTime: [
          this.startTime,
          this.f?.status?.value === configConstans.playlistStatuses.SCHEDULED.value && Validators.required,
        ],
      },
      {
        validator: [createPlaylistValidator()],
      }
    );
  }

  handleCreate(): void {
    if (this.playlistForm.invalid) {
      CommonHelpers.validateAllFormFields(this.playlistForm);
      return;
    }
    this.activeChannel$.pipe(first()).subscribe((activeChannel) => {
      this.isLoading = true;
      const { uuid: channelId } = activeChannel;
      const { title, description, status, mode, startDate, startTime } = this.playlistForm.value;
      const start_date =
        startDate && startTime
          ? new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              startTime.getHours(),
              startTime.getMinutes(),
              startTime.getSeconds()
            )
          : null;
      const slug = CommonHelpers.createSlug(title);
      const newPlaylist = {
        title,
        description,
        status,
        mode,
        start_date,
        slug,
        channel: channelId,
      } as Playlist;
      const observable =
        this.type === 'AUDIO'
          ? this.audioPlaylistService.createPlaylist(newPlaylist)
          : this.videoPlaylistService.createPlaylist(newPlaylist);
      observable.subscribe(
        (res) => {
          this.notifier.notify('success', `Successfully created ${title} playlist`);
          this.dialogRef.close(res);
        },
        (error) => {
          this.notifier.notify('error', error);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    });
  }

  handleModeChange(event: any) {
    switch (event.value) {
      case configConstans.playlistModes.MANUAL.value:
        this.playlistForm.controls['status'].setValue(configConstans.playlistStatuses.DRAFT.value);
        break;
      case configConstans.playlistModes.SCHEDULED.value:
        this.playlistForm.controls['status'].setValue(configConstans.playlistStatuses.SCHEDULED.value);
        break;
      default:
        break;
    }
  }
}

export class CreatePlaylistDialogModel {
  constructor(public type: CreateType = 'VIDEO', public mode?: string, public date?: Date) {}
}
