import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { environment as env } from '@env/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class VideoImporterService {
  constructor(private apiService: ApiService) {}

  fetchYoutubeVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/youtube/retrieve-youtube-videos-by-url/`;
    return this.apiService.post(url, payload);
  }

  importSelectedYoutubeVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/youtube/import-selected-videos/`;
    return this.apiService.post(url, payload);
  }

  fetchVimeoVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/vimeo/retrieve-vimeo-videos-by-channel-url/`;
    return this.apiService.post(url, payload);
  }

  fetchAllVimeoVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/vimeo/retrieve-vimeo-videos/`;
    return this.apiService.post(url, payload);
  }

  importSelectedVimeoVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/vimeo/import-selected-videos/`;
    return this.apiService.post(url, payload);
  }

  fetchDropboxVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/dropbox/retrieve-dropbox-videos-folder-path/`;
    return this.apiService.post(url, payload);
  }

  importSelectedDropboxVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/dropbox/import-selected-videos/`;
    return this.apiService.post(url, payload);
  }

  fetchImportHistory(params: any) {
    let url = `${env.serverUrl}/api/v3/video-importer/import-history/`;

    // Convert params dictionary to query string format
    const queryString = new URLSearchParams(params).toString();

    // Append query string to the URL if params are present
    if (queryString) {
      url += `?${queryString}`;
    }

    return this.apiService.get(url);
  }

  retryImportItem(uuid: string, oauth_token: string = '') {
    let url = `${env.serverUrl}/api/v3/video-importer/import-history/${uuid}/retry-import/`;
    return this.apiService.post(url, { oauth_token: oauth_token });
  }

  retryImportItemsBulk(uuids: string[], imported_from: string, oauth_token: string = '') {
    let url = `${env.serverUrl}/api/v3/video-importer/import-history/bulk-retry-import/`;
    return this.apiService.post(url, { import_ids: uuids, imported_from: imported_from, oauth_token: oauth_token });
  }

  deleteImportItem(uuid: string) {
    let url = `${env.serverUrl}/api/v3/video-importer/import-history/${uuid}`;
    return this.apiService.delete(url);
  }
}
