<div class="dialog-container">
  <h2 mat-dialog-title class="dialog-title">
    <mat-icon color="primary" class="dialog-icon">help_outline</mat-icon>
    Confirm S3 Feature Request
  </h2>
  <div mat-dialog-content class="dialog-content">
    <p>{{ data.message }}</p>
  </div>
  <div mat-dialog-actions align="end" class="dialog-actions">
    <button mat-button class="cancel-btn" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
  </div>
</div>
